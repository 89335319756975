import React, { Fragment } from 'react';
import NextSectionLink from '../../learningCenter/NextSectionLink';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const BreedingAnimialsPricing = ({ match }) => {
  const path =
    `${match.url.substring(0, match.url.lastIndexOf('/'))}/finished-cattle`;
  return (
    <Fragment>
      <h2 data-cy="breeding-bulls-pricing-title"
        className="caption">
          Pricing
      </h2>
      <h4>Animals for breeding</h4>
      <p className="standard-para">BeefBook applies the Breedplan Estimated Breed Values (EBVs) as a combined % ranking together with the GoodBeef Index scoring, to establish an indicative £value for breeding animals. The combined EBV rankings and associated GoodBeef Index quality grades are:</p>
      <ul className="standard-bullet">
        <li>Top 10% <span className="highlight pink">Supreme</span> exceptional animal across the spectrum of traits for the breed</li>
        <li>Top 20% <span className="highlight pink">Prime</span> high-class animal across the range of genetic traits</li>
        <li>Top 30% <span className="highlight pink">Classic</span> well-rounded animal with some outstanding genetic traits</li>
        <li>Top 50% <span className="highlight pink">Superior</span> good working animal or suckler cow</li>
        <li><span className="highlight pink">Standard</span> baseline average value for breeding livestock</li>
      </ul>
      <div className="intro-divider"></div>
      <p className="standard-para">Where EBVs are unavailable BeefBook defaults to the Good Beef Index scoring to provide an indicative £value.</p>
      <p className="standard-para">BeefBook analyses breeding animal sales prices achieved from markets, auctions and private sales, to compile a floor £value for each class of livestock (eg Maiden Hiefers).</p>
      <p className="standard-para">The indicative value per animal takes into account the floor market value, the animal’s EBV ranking and Good Beef Index scores</p>
      <p className="standard-para">The buyer and seller use the indicative £value together with the authentication data to agree a sales price</p>
      <NextSectionLink path={ path } label="Animals for beef" />
    </Fragment>
  );
};

BreedingAnimialsPricing.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default BreedingAnimialsPricing;
