import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PricingGuidlinesMenu from './PricingGuidelinesMenu';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { sideMenuIsOpen } from '../../header/reducer';
import '../../learningCenter/learning-center.scss';
import CarcassPricing from './CarcassPricing';
import BreedingAnimialsPricing
  from './BreedingAnimalsPricing';

const BBPricingGuidelines = ({ match }) => {
  const menuOpen = useSelector(sideMenuIsOpen);
  const className = menuOpen ? 'mobile-menu-visible' : null;
  return (
    <div id='learning-center' className={ className }>
      <Route path={`${match.url}`} render={
        (props) => <PricingGuidlinesMenu { ...props} />
      } />
      <div className="content">
        <Switch>
          <Route exact path={ match.url } render={
            (props) =>
              <Redirect { ...props} to={`${match.url}/breeding-cattle`} />
          } />
          <Route path={`${match.url}/breeding-cattle`} render={
            (props) => <BreedingAnimialsPricing { ...props} />
          } />
          <Route path={`${match.url}/finished-cattle`} render={
            (props) => <CarcassPricing { ...props} />
          } />
        </Switch>
      </div>
    </div>
  );
};

BBPricingGuidelines.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default BBPricingGuidelines;
