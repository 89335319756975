import React from 'react';
import ShortArrow from '../../util/icons/components/ShortArrow';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSideMenuOpen, setMobileMenuOpen } from '../../header/actions';
import '../../learningCenter/learning-center-menu.scss';
import PropTypes from 'prop-types';

const PriceGuildelinesMenu = ({ match }) => {
  const dispatch = useDispatch();
  const closeSideMenu = () => dispatch(setSideMenuOpen(false));
  const closeMobileMenu = () => dispatch(setMobileMenuOpen(false));
  const handleSelection = () => {
    closeMobileMenu();
    window.scrollTo({ top: 0 });
  };
  return (
    <ul className='learning-center-menu'>
      <li className="header">
        <span className="learning">Pricing</span>
        <span className="center">Guildlines</span>
      </li>
      <li className="back" onClick={ closeSideMenu }>
        <ShortArrow />
        <span className='text'>Back</span>
      </li>
      <li className='top-level' onClick={ handleSelection }>
        <NavLink to={`${match.url}/breeding-cattle`}
          className='learning-center-menu-item'
          activeClassName='active'>
          <div className="text">
            Animals for breeding
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
      <li className='top-level' onClick={ handleSelection }>
        <NavLink to={`${match.url}/finished-cattle`}
          className='learning-center-menu-item'
          activeClassName='active'>
          <div className="text">
            Animals for beef
            <div className="line"></div>
          </div>
        </NavLink>
      </li>
    </ul>
  );
};

PriceGuildelinesMenu.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default PriceGuildelinesMenu;
