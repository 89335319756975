/* eslint-disable max-len */
import React, { Fragment } from 'react';

const CarcassPricing = () => (
  <Fragment>
    <h2 className="caption">Pricing</h2>
    <h4>Animals for Beef</h4>
    <p className="standard-para">BeefBook applies the Good Beef Index scoring and grading algorithm to establish an indicative £ value per Kg of Carcass Weight (CW). The Good beef Index scores and associated grades are:</p>
    <ul className="standard-bullet">
      <li>110+ <span className="highlight">Supreme</span> suckler pure-bred beef, 100% pasture fed and a sublime eating experience</li>
      <li>100+ <span className="highlight">(Prime)</span> suckler pure-bred grass-fed beef, abundant marbling, regenerative farming</li>
      <li>90+ <span className="highlight">(Classic)</span> suckler beef, good marbling, following environmentally sensitive practices</li>
      <li>80+ <span className="highlight">(Superior)</span> cross bred suckler beef, grass fed and grazed outdoors</li>
      <li>70+ <span className="highlight">(Standard)</span> dairy cow dam, intensively reared and grain fed</li>
    </ul>
    <div className="intro-divider"></div>
    <p className="standard-para">BeefBook applies a % value uplift to the Standard grade price, to derive an indicative £ value per grade (Superior, Classic, Prime and Supreme grades).</p>
    <p className="standard-para">The published AHDB average retail price per Kg, together with the published retail beef prices from 15 retail outlet types, form the basis of the £ value uplift calculation per grade.</p>
    <p className="standard-para">The baseline price to which the uplift % is applied is taken from the AHDB published £ price per carcase Kg of an R4L animal at slaughter.</p>
  </Fragment>
);

export default CarcassPricing;
